.contact-form {
  width: 100%;
  margin-top: 20px;
  min-height: 600px;
  form{
    min-height: 350px;
    position: relative;
  }
  ul {
    padding: 0;
    margin: 0;
    display: grid;
    gap: 3px;
    justify-content: center;
    position: absolute;
    
    
    li {
      padding: 0;
      margin: 0;
      list-style: none;
      margin-bottom: 10px;
      width: 100%;

      opacity: 0;
      overflow: hidden;
      display: block;
      clear: both;
      position: relative;
      animation: fadeInUp 2s 2s;
      animation-fill-mode: forwards;
    }

    li.half {


      &:first-child {
        margin-left: 0;
        margin-right: 3px;
      }
      &:nth-child(2) {
        grid-column: 2/3;
      }
      &:nth-child(3) {
        grid-column: 1/3;
      }
      &:nth-child(4) {
        grid-column: 1/3;
      }
      &:nth-child(5) {
        display: contents;
      }
      
    }

  }

  input[type='text'],
  input[type='email'] {
    width: 100%;
    border: 0;
    background: #115173;
    height: 50px;
    font-size: 16px;
    color: #fff;
    padding: 0 20px;
    box-sizing: border-box;
  }

  textarea {
    width: 100%;
    border: 0;
    background: #115173;
    height: 50px;
    font-size: 16px;
    color: #fff;
    padding: 20px;
    box-sizing: border-box;
    min-height: 150px;
  }
  text-area[placeholder="Message"]{
    width: 100%;
  }
  .submit{
    justify-self: start;
    .flat-button {
      color: #f79b26;
      font-size: 15px;
      width: 50%;
      padding: 10px;
      letter-spacing: 3px;
      text-decoration: none;
      border: 1px solid #f79b26;
      //border-radius: 4px;
      animation: fadeInUp 2s 2s;
      animation-fill-mode: forwards;
      background: 0 0;
      text-transform: uppercase;
      text-align: center;

      margin: 0;
      float: right;
      &:hover{
        background-color: #fff;

      }
    }
  }
}

.illustration{
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  left: 50%;
  top: 20%;
  
  img{
    width: 70%;
    height: 60%;
    animation: fadeInUp 2s 2s;
    animation-fill-mode: forwards;
  }
}
