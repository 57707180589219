@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');

.Card{
     background: #011F30;
     border-radius: 15px;
     width: 960px;
     height: 380px;
     display: flex;
     flex-direction: row;
     justify-content: center;
     img{
          height: 100%;
          width: 480px;
          border-radius: 15px 0px 0px 15px;
          background-color: #fff;
     }
     .card-content{
          padding: 40px;
          color: #fff;
          font-family: 'Inter', sans-serif;
          
          .Card-title{
               font-weight: 700;
               line-height: 50px;
               font-size: 40px;
               text-transform: capitalize;
               color: #fff;
               text-decoration: none;
          }
          .description{
               font-size: 15px;
               line-height: 23px;
               letter-spacing: 0.01em;
               color: #CCCCCC;
          }
          .button{
                    color: rgb(0, 99, 228);
                    font-size: 15px;
                    text-decoration: none;
                    margin-top: 1rem;
                    display: inline-block;
                    font-weight: bold;
                    padding: .5rem;
                    margin-left: -.5rem;
                    position: relative;
                    clip-path: polygon(0 0, 100% 0, 100% 100%,0% 100%);
               &:before, &:after{
                    position: absolute;
                    content: "";
                    border-bottom: 3px solid;
                    border-radius: 1em;
                    bottom: .3em;
                    transition: transform .5s cubic-bezier(0.075, 0.82, 0.165, 1);
               }
               &:before{
                    width: 1em;
                    transform-origin: left;

               }
               &:after{
                    width: 82%;
                    left: 1em;
                    transform: translateX(110%);
               }
               &:hover::before{
                    transform: scaleX(0.3);

               }
               &:hover::after {
                    transform: translateX(0);

               }
          }

     }
}