.nav-bar{
    background-color: #181818;
    width: 80px;
    height: 100%;
    position: absolute;
    top:0;
    z-index: 3;
    min-height: 500px;
}

.logo{
    display: block;
    padding: 8px 0;
    img{
        display: block;
        padding: 8px 0;
        width: 50px;
        height: auto;
        margin: auto;
        
    }
}

nav{
    display: block;
    text-align: center;
    height: 210px;
    top: 50%;
    margin-top:120px;
    width: 100%;
    a{
        font-size: 22px;
        color: #4d4d4e;
        display: block;
        line-height: 51px;
        height: 51px;
        position: relative;
        text-decoration: none;
        
        i{
            transition: all .3 ease-out;
        }
        &:hover{
            color: #f79b26;
            svg{
                opacity: 0;
            }
            &::after{
                opacity: 1;
            }
        }

        &:after{
            content:'';
            font-size: 9px;
            letter-spacing: 2px;
            position: absolute;
            bottom: 0;
            display: block;
            width: 100%;
            text-align: center;
            opacity: 0;
            transition: all 0.3 ease-out;
        }

        &:first-child{
            &::after{
                content:"HOME";

            }
        }
    }
    a.about-link{
        &::after{
            content: "ABOUT";
        }
    }
    a.project-link{
        &::after{
            content: "PROJECT";
        }
    }
       a.contact-link{
        &::after{
            content: "CONTACT";
        }
    }
    a.active{
        svg{
            color: #f79b26;
        }
    }
}
ul{
    position: absolute;
    bottom: 20px;
    width: 100%;
    display: block;
    padding:0;
    list-style: none;
    text-align: center;
    margin: 0;

    li{
        a{
            padding: 7px 0;
            display: block;
            font-size:15px;
            line-height: 16px;
            .icon-size{
                height: 20px;
            }
            &:hover svg{
                color: #f79b26;

            }
        }
    }
}