.size{
     
     display: flex;
     justify-content: center;
     align-items: center;
     position: relative;
     top: 40%;
     .slick-slider{
     width: 960px !important;
     height: 380px !important;
     }
}