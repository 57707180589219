.home-page{
    .text-zone{
        position: absolute;
        left: 10%;
        top: 50%;
        transform: translateY(-50%);
        width: 40%;
        max-width: 90%;

        img{
            // width: 32px;
            margin-bottom: -3px;
            margin-left: 20px;
            opacity: 0;
            height: auto;
            animation: rotateIn 1s linear both;
            animation-delay: 1.4s;
            width: 45px;
        }

    }

    h1{
        color: #fff;
        font-size: 53px;
        margin: 0;
        font-family: "Coolvetica";
        font-weight: 400;
        &::before{
            content: '<h1>';
            font-family: 'La Belle Aurore';
            color: #f79b26;
            font-size:18px;
            position: absolute;
            margin-top: -40px;
            left: 15px;
            opacity: 0.6;

        }
        &::after{
            content: '</h1>';
             font-family: 'La Belle Aurore';
            color: #f79b26;
            font-size:18px;
            position: absolute;
            margin-top: 18px;
            margin-left: 20px;
            animation: fadeIn 1s 1.7s backwards;
            opacity: 0.6;
        }
    }

}
h2{
    color: #8d8d8d;
    margin-top: 20px;
    font-weight: 400;
    font-size: 11px;
    font-family: sans-serif;
    letter-spacing: 3px;
    animation: fadeIn 1s 1.8s backwards;
}

.home-button{
    color: #f79b26;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 4px;
    font-family: sans-serif;
    text-decoration: none;
    padding: 10px 18px;
    border: 1px solid #f79b26;
    margin-top: 25px;
    float: left;
    animation: fadeIn 1s 1.8s backwards;
    white-space: nowrap;

    &:hover{
        background-color: #f79b26;
        color: #333;

    }
}
