.logo-container {
  z-index: 0;
  width: 500px;
  //height: 609px;
 
  position: absolute;
  top: 0;
  right: 15%;
  bottom: 0;
  left: auto;
  margin: auto;
  padding-top: 1rem;

  .solid-logo {
    padding-top: 2rem;
    position: absolute;
    top: auto;
    right: auto;
    bottom: auto;
    left: 0;
    margin: auto;
    width: 100%;
    z-index: 1;
  }
}

